<template>
    <div class="page-main">
         <a-form :form="searchForm"
            layout="inline">
            <a-form-item label="批次号">
                <a-input v-model="searchForm.batch_id"
                        placeholder="批次号" />
            </a-form-item>
            <a-form-item label="状态" >
                <a-select v-model="searchForm.status" style="width: 120px" >
            
                    <a-select-option value="0">
                        未分配
                    </a-select-option>
                    <a-select-option value="1">
                        已分配
                    </a-select-option>
                    <a-select-option value="4">
                        已停止
                    </a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item >
            <a-button type="primary"
                        icon="search"
                        @click="handleSearch">
                搜索
            </a-button>
            </a-form-item>
            <a-form-item >
            <a-button 
                        icon="reload"
                        @click="resetSearchForm">
                重置
            </a-button>
            </a-form-item>
        </a-form>
        <a-card :bordered='false'>
            <a-row style="display:flex;justify-content: flex-end;margin-bottom:20px;">
                <a-button style="margin-right:20px;" type="primary" icon="download" @click="downloadClick">下载模板</a-button>
                <a-upload
                    accept='.xlsx'
                    :show-upload-list="false"
                    action="scrm/upload.file"
                    :before-upload="beforeUpload"
                    @change="handleFileChange">
                    <a-button style="margin-right:20px;"  type="primary" icon="upload">上传文件</a-button>
                </a-upload>
                <a-button  type="primary" style="margin-right:20px;"  icon="user-add" @click="expandClick">拓客</a-button>
                <a-button  type="primary" icon="user-add" @click="handleClickExport">导出</a-button>
            </a-row>
             <a-table 
                :columns="expandColumns" 
                :data-source="expandData"
                :row-key="record => record._id"
                :pagination="batchPagination"
                @change="handleBatchTableChange"
            >
                <span slot="status" slot-scope="status">
                    <a-tag
                        :color="status === 0 ? 'geekblue' : status===1||status===2||status===3? 'green' : 'red'"
                    >
                        {{formatStatus(status) }}
                    </a-tag>
                </span>
                <template slot="operation" slot-scope="text,record">
                    <div class="operation-wrapper">
                        <a-button v-if="record.status!==0" type="link" @click="handleGetDetail(record)">查看</a-button>
                        <a-button v-if="record.status===0" type="link" @click="handleOpenSetting(record)">分配</a-button>
                        <a-popconfirm title="确定要删除吗？" @confirm="handleDelete(record)">
                            <a-button v-if="record.status===0" type="link" style="color:#ff4d4f;">删除</a-button>
                        </a-popconfirm>
                        <a-popconfirm title="确定要停止吗？" @confirm="handleStop(record)">
                            <a-button v-if="record.status===1" type="link" style="color:#ff4d4f;" >停止</a-button>
                        </a-popconfirm>
                    </div>  
                </template>
            </a-table>
        </a-card>
        <a-modal v-model="isShowExpand" title="拓客设置" width="700px" @ok="handleConfirm">
            <a-form>
                 <a-form-item 
                    label="批次号" 
                    :label-col="{span:4}" 
                    :wrapper-col="{ span: 16 }">
                    <a-select v-model="expandForm.batch_id" style="width: 270px" placeholder="请选择批次号" @change="batchChange" >
                        <a-select-option v-for="(item,index) in batchList" :key="index" :value="item.batch_id">
                           {{item.batch_id}}
                        </a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item 
                    label="未分配数"
                    :label-col="{span:4}" 
                    :wrapper-col="{ span: 16 }"
                    >
                        {{selectObj.number || 0}}
                </a-form-item>
                <a-form-item 
                    label='工作时间'
                    :label-col="{span:4}" 
                    :wrapper-col="{ span: 16 }">
                    <a-time-picker :value="expandForm.start_time?moment(expandForm.start_time,'HH:mm'):null" placeholder="开始时间" format="HH:mm" @change="updateStartTime" /> - <a-time-picker :value="expandForm.end_time?moment(expandForm.end_time,'HH:mm'):null" placeholder="结束时间" format="HH:mm" @change="updateEndTime"/>
                </a-form-item>
                <a-form-item label='业务员设置' :label-col="{span:4}" >
                    <a-row>
                        <a-button type="primary" size="small" style="font-size:12px" @click="handleAddClick">新增</a-button>
                    </a-row>
                    <a-table
                        :columns="settingColumns" 
                        :data-source="settingData"
                        :row-key="record => record._id"
                        :pagination="false"
                        size="small"
                        :bordered="false"
                    >
                        <template slot="saleperson" slot-scope="text,record,i">
                            <a-select
                                show-search
                                v-model="record.user_id"
                                placeholder="请选择业务员"
                                :default-active-first-option="false"
                                :show-arrow="false"
                                :filter-option="false"
                                :not-found-content="null"
                                @search="handleSelectSearch"
                                @change="handleSelectChange"
                                @select="handleSelect($event,i)"
                                >
                                <a-select-option v-for="(item,index) in salespersonList" :key="index" :value="item.user_id">
                                    {{ item.username }}
                                </a-select-option>
                            </a-select>
                        </template>
                        <template slot="num" slot-scope="text,record,index">
                            <a-input v-model="record.number" @change="handleChangeNum(index)" :min="1" type="number"/>
                        </template>
                         <template slot="operation" slot-scope="text,record,index">
                            <div class="operation-wrapper">
                                <a-button type="link" style="color:#ff4d4f;" @click="handleDeleteOne(record,index)" :disabled="settingData.length==1">删除</a-button>
                            </div>  
                        </template>
                    </a-table>
                </a-form-item>
                <a-form-item label='邀请语' :label-col="{span:4}" :wrapper-col="{ span: 16 }"> 
                    <a-textarea
                         v-model="expandForm.verify"
                        placeholder="邀请语"
                        :auto-size="{ minRows: 5}"
                    ></a-textarea>
                </a-form-item>
            </a-form>
        </a-modal>
         <a-modal v-model="isShowDetail" title="拓客详情" :footer="null" width="920px">
             <a-row style="margin-bottom:20px">
                 <a-form :form="detailParam"
                    layout="inline">
                    <a-form-item label="业务员">
                         <a-select
                                show-search
                                v-model="detailParam.user_id"
                                placeholder="请选择业务员"
                                :default-active-first-option="false"
                                :show-arrow="false"
                                :filter-option="false"
                                :not-found-content="null"
                                @search="handleSelectSearch"
                                @change="handleSelectChange"
                                style="width:100px"
                                >
                                <a-select-option v-for="(item,index) in salespersonList" :key="index" :value="item.user_id">
                                    {{ item.username }}
                                </a-select-option>
                            </a-select>
                    </a-form-item>
                    <a-form-item label="状态" >
                        <a-select v-model="detailParam.status" style="width: 100px" >
                    
                            <a-select-option value="1">
                                未开始
                            </a-select-option>
                            <a-select-option value="2">
                                已发送邀请
                            </a-select-option>
                            <a-select-option value="3">
                                已接收
                            </a-select-option>
                            <a-select-option value="4">
                                未找到
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item 
                        label='邀请时间'>
                        <a-range-picker style="width:240px" @change="handleDetailDateChange" v-model="detailDateRange"/>
                    </a-form-item>
                    <a-form-item >
                    <a-button type="primary"
                                icon="search"
                                @click="handleSearchDetail">
                        搜索
                    </a-button>
                    
                    </a-form-item>
                    <a-form-item >
                        <a-button 
                                icon="reload"
                                @click="resetDetailForm">
                        重置
                    </a-button>
                    </a-form-item>
                </a-form>
             </a-row>
              <a-table 
                :columns="detailColumns" 
                :data-source="detailData"
                :row-key="record => record._id"
                :pagination="detailPagination"
                @change="handleDetailTableChange"
            >
                <span slot="status" slot-scope="status">
                    <a-tag
                        :color="status === 1 ? 'geekblue' : status===2? 'green' : 'red'"
                    >
                        {{formatDetailStatus(status) }}
                    </a-tag>
                </span>
                <div slot="time" slot-scope="text">
                    {{text | parseTime('{y}-{m}-{d} {h}:{i}:{s}')}}
                </div>
            </a-table>
         </a-modal>
    </div>
</template>
<script>
const expandColumns = [
    {
        title: '批次号',
        dataIndex: 'batch_id',
        align:'center',
    },
    {
        title: '批次内数量',
        dataIndex: 'number',
        align:'center',
    },
    {
        title: '未开始',
        dataIndex: 'ready_count',
        align:'center',
    },
    {
        title: '已发送邀请',
        dataIndex: 'send_count',
        align:'center',
    },
    {
        title: '已接收',
        dataIndex: 'received_count',
        align:'center',
    },
    {
        title: '未找到',
        dataIndex: 'unfound_count',
        align:'center',
    },
    {
        title: '状态',
        dataIndex: 'status',
        align:'center',
        scopedSlots: { customRender: "status" }
    },
    {
        title: '工作开始时间',
        dataIndex: 'start_time',
        align:'center',
    },
    {
        title: '工作结束时间',
        dataIndex: 'end_time',
        align:'center',
    },
    {
        title: '邀请语',
        dataIndex: 'verify',
        align:'center',
    },
    {
        title: "操作",
        width: 120,
        align:'center',
        scopedSlots: { customRender: "operation" },
    },
]
const settingColumns = [
    {
        title: '业务员',
        dataIndex: 'saleperson',
        scopedSlots: { customRender: 'saleperson' },
    },
    {
        title: '分配数量',
        width:160,
        dataIndex: 'number',
        scopedSlots: { customRender: 'num' },
    },
    {
        title: "操作",
        width: 120,
        align:'center',
        scopedSlots: { customRender: "operation" },
    },
]
const detailColumns = [
    {
        title: '手机号',
        dataIndex: 'mobile',
        align:'center'
    },
    {
        title: '业务员',
        dataIndex: 'username',
        align:'center'
    },
    {
        title: '状态',
        dataIndex: 'status',
        align:'center',
        scopedSlots: { customRender: "status" }
    },
    {
        title: '发送邀请日期',
        dataIndex: 'end_time',
        align:'center',
        scopedSlots: { customRender: "time" }
    }
]
import {
    mapState
  } from 'vuex'
  import moment from 'moment';
export default {
    name:'expand-customer',
    data(){
        return{
            detailDateRange:undefined,
            searchForm:{},
            detailParam:{
                user_id:'',
                status:null,
                fromTime:'',
                toTime:''
            },
            expandColumns,
            expandData:[],
            detailData:[],
            settingColumns,
            detailColumns,
            settingData:[{
                _id:1,
                user_id:'',
                number:0
            }],
            isShowExpand:false,
            isShowDetail:false,
            fileLoading:false,
            expandForm:{
                task_type:2
            },
            fileUrl:'',
            batchList:[],
            salespersonList:[],
            batch_id:'',
            selectBatch:{},
            selectObj:{},
            batchPagination: {
                total: 0,
                pageSize: 10,//每页中显示10条数据
                current: 1,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ["10", "20","30"],//每页中显示的数据
                showTotal: total => `共有 ${total} 条数据`,  //分页中显示总的数据
            },
            detailPagination: {
                total: 0,
                pageSize: 10,//每页中显示10条数据
                current: 1,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ["10", "20","30"],//每页中显示的数据
                showTotal: total => `共有 ${total} 条数据`,  //分页中显示总的数据
            },
        }
    },
    computed: {
        ...mapState(['mqttInit','company'])
    },
    watch:{
        mqttInit:{
            handler(val){
                if(val){
                    this.handleGetCustomerExpand()
                    this.getBatchList()
                    this.getEntrustList()
                }
            },
            immediate:true
        }
    },
    methods:{
        moment,
        handleSearchDetail(){
            this.detailPagination.current = 1
            this.getDetail()
        },
        resetDetailForm(){
            this.detailParam = {
                user_id:'',
                status:null,
                fromTime:'',
                toTime:''
            }
            this.detailDateRange = undefined
        },
        disabledDate(current){
             return current && current > moment(new Date()).endOf('day');
        },
        handleDetailDateChange(date,dateString){
            if(dateString[0]){
                let begin_time = Math.round(new Date(dateString[0]).getTime()/1000).toString()
                let end_time = Math.round(new Date(dateString[1]).getTime()/1000).toString()
                this.detailParam.fromTime = begin_time
                this.detailParam.toTime = end_time
            }else{
                this.detailParam.fromTime = undefined
                this.detailParam.toTime = undefined
            }
            
        },
        handleDeleteOne(row,index){
           this.settingData.splice(index,1)
        },
        handleAddClick(){
            this.settingData.push({
                _id:this.settingData.length+1,
                user_id:'',
                number:0
            })
            
        },
        handleClickExport(){
            let payload = {
                msgId: "expandExport",
                company:this.company
            }
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/expand/exportExpand",
                payload,
                },
                (topic, data) => {
                    // console.log(data)
                    if(data.success){
                        this.$message.success('导出成功')
                        window.open(data.url)
                    }
                },
                this
            );
        },
        //删除批次
        handleDelete(record){
            let payload = {
                msgId: "deleteBatch",
                data:{
                    batch_id:record.batch_id
                }
            }
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/expand/deleteBatch",
                payload,
                },
                (topic, data) => {
                    if(data.code==200){
                        this.$message.success('删除成功')
                        this.handleGetCustomerExpand()
                    }
                },
                this
            );
        },
        //停止批次
        handleStop(record){
            let payload = {
                msgId:'stopBatch',
                data:{
                    batch_id:record.batch_id
                }
            }
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/expand/stopBatch",
                payload,
                },
                (topic, data) => {
                    if(data.code==200){
                        this.$message.success('停止成功')
                        this.handleGetCustomerExpand()
                    }
                },
                this
            );
        },
        handleSelectSearch(val){
            this.getEntrustList(val)
        },
        handleSelectChange(){
            this.getEntrustList()
        },
        handleSelect(e,i){
           let res =  this.settingData.filter(item=>item.user_id && item.user_id == e)
           if(res.length>1){
               this.$message.error('您选择了重复的业务员,请重新选择！')
               this.settingData[i].user_id = ''
           }
        },
        //获取批次列表
        getBatchList(){
            let payload={
                msgId:'listBatch',
                data:{}
            }
            this.$mqtt.doPublish(
                {
                pubTopic: 'scrm/expand/listBatch',
                payload,
                },
                (topic, data) => {
                    // console.log(data)
                    this.batchList = data.batch_list
                },
                this
            );
        },
        batchChange(val){
            this.selectObj = this.batchList.filter(item=>{
                return item.batch_id==val
            })[0]
        },
        //获取业务列表
        getEntrustList(name=''){
            let payload = {
                msgId:'userlist',
                data: {
                    username:name,
                    pageSize:100,
                    pageNum:1
                },
            };
        // console.log(payload)
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/weUser/searchUserByPage",
                payload,
                },
                (topic, data) => {
                    if(data.code==200){
                        this.salespersonList = data.data.res
                    }
                },
                this
            );
        },
        handleSearch(){
            this.batchPagination.current = 1
            this.handleGetCustomerExpand()
        },

        resetSearchForm () {
            this.searchForm = {
                batch_id: '',
                status: ''
            }
        },
        downloadClick(){
            window.open('http://scrm.facelisten.com/files/模板.xlsx')
        },
        uploadClick(){
            this.isShowUpload = true
        },
        expandClick(){
            this.expandForm = {
                task_type:2
            }
            this.settingData = [{_id:1,
                user_id:'',
                number:0}]
            this.selectObj.number = 0
            this.isShowExpand = true
        },
        handleDetailTableChange(pagination){
            this.detailPagination = pagination
            this.getDetail()
        },
        handleGetDetail(record){
            this.detailPagination.current = 1
            this.selectBatch = record
            this.isShowDetail = true
            this.resetDetailForm()
            this.getDetail()
        },
        getDetail(){
            let payload = {
                msgId:'listCustomerExpand',
                data:{
                    batch_id:this.selectBatch.batch_id,
                    pageNum:this.detailPagination.current,
                    pageSize:this.detailPagination.pageSize
                }
            }
            if(this.detailParam.status){
                payload.data.status = this.detailParam.status
            }
            if(this.detailParam.user_id){
                payload.data.user_id = this.detailParam.user_id
            }
            if(this.detailParam.fromTime){
                 payload.data.fromTime = this.detailParam.fromTime
                 payload.data.toTime = this.detailParam.toTime
            }
            // console.log(JSON.stringify(payload))
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/expand/listCustomerExpand",
                payload,
                },
                (topic, data) => {
                    if(data.code==200){
                        this.detailPagination.total = data.data.count
                        this.detailData = data.data.res
                    }
                },
                this
            );
        },
        //点击分配
        handleOpenSetting(item){
            this.expandForm.batch_id = item.batch_id
            this.selectObj = this.batchList.filter(obj=>{
                return obj.batch_id ===item.batch_id
            })[0]
            this.isShowExpand = true
        },
        handleChangeNum(index){
            if(!this.selectObj.batch_id){
                this.$message.error('请先选择批次')
                this.settingData[index].number = 0
                return
            }
            if(!this.settingData[index].user_id){
                this.$message.error('请先选择业务人员')
                this.settingData[index].number = 0
                return
            }
           let count = this.settingData.reduce((total,item)=>{
               return total+Number(item.number)
           },0)
           if(this.selectObj.number<count){
               this.$message.error('分配数量不能大于批次内总数')
               if(this.settingData.length===1){
                   this.settingData[0].number = this.selectObj.number
                   return
               }
               let others = this.settingData.filter((item,i)=>{
                   return i !==index
               })
               let otherCount = others.reduce((total,item)=>{
                   return total+Number(item.number)
               },0)
               this.settingData[index].number =  this.selectObj.number-otherCount
               return
           }
        },
        updateStartTime(t,ts){
            this.$set(this.expandForm,'start_time',ts)
        },
         updateEndTime(t,ts){
            this.$set(this.expandForm,'end_time',ts)
        },
        handleConfirm(){
            if(!this.expandForm.batch_id){
                this.$message.error('请先选择批次')
                return
            }
            if(!this.expandForm.start_time || !this.expandForm.end_time){
                this.$message.error('请先选择工作时间')
                return
            }
            let empty = this.settingData.filter(item=>{
                return !item.number || !item.user_id
            })
            if(empty.length>0){
                this.$message.error('请完善业务员设置')
                return
            }
            let count = this.settingData.reduce((total,item)=>{
               return total+Number(item.number)
           },0)
           if(count===0){
               this.$message.error('请先进行业务员设置')
                return
           }
           let that = this
           if(count<this.selectObj.number){
               this.$confirm({
                    content: `您还有${this.selectObj.number-count}个手机号未分配,如不分配，剩余手机号将不能在本批次继续分配，您是否要继续？`,
                    okText:'确定',
                    cancelText:'取消',
                    onOk() {
                        that.createNewExpand()
                    }
                })
           }else{
               this.createNewExpand()
           }

        },

        beforeUpload(){

        },
        handleFileChange(info){
             if (info.file.status === 'uploading') {
                this.fileLoading = true;
                return;
            }
            if (info.file.status === 'done') {
                // console.log(info)
                this.fileLoading = false;
                this.fileUrl = info.file.response.url
                this.handleUploadFile()
            }
        },
        //创建新的拓客任务
        createNewExpand(){
            this.isShowExpand = false
            this.settingData.forEach(item=>{
                item.number = Number(item.number)
            })
            let payload = {
                msgId:'batchExpand',
                data:{
                    ...this.expandForm,
                    user_list:this.settingData,
                    company:this.company
                }
            }
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/expand/batchExpand",
                payload,
                },
                (topic, data) => {
                    // console.log(data)    
                    // if(data.code==200){
                        this.expandForm = {}
                        this.$message.success('创建成功')
                        this.handleGetCustomerExpand()
                    // }
                },
                this
            );
        },
        handleBatchTableChange(pagination){
            this.batchPagination = pagination
            this.handleGetCustomerExpand()
        },
        //获取拓客任务列表
        handleGetCustomerExpand(){
            let payload = {
                msgId:'batchlist',
                data:{
                    ...this.searchForm,
                    pageSize:this.batchPagination.pageSize,
                    pageNum:this.batchPagination.current,
                    company:this.company
                }
            }
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/expand/batchlist",
                payload,
                },
                (topic, data) => {
                    if(data.code==200){
                        this.expandData = data.data.res
                        this.batchPagination.total = data.data.count
                    }
                },
                this
            );
        },
        handleUploadFile(){
            let payload = {
                msgId:'uploadExcel',
                file:this.fileUrl,
                company:this.company
            }
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/expand/analyzeUserIds",
                payload,
                },
                (topic, data) => {
                    this.$notification.open({
                        message: '导入成功',
                        description:`批次号：${data.batch_id}`,
                        duration: 0,
                    });
                    this.getBatchList()
                },
                this
            );
        },
        formatStatus(status){
            switch (status){
                case 0:
                    return '未分配';
                case 1:
                    return '已分配';
                case 3:
                    return '已完成';
                case 4:
                    return '已停止'
            }
        },
        formatDetailStatus(status){
            switch (status){
                case 1:
                    return '未开始';
                case 2:
                    return '已发送邀请';
                case 3:
                    return '已接收';
                case 4:
                    return '未找到'
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .show-item{
        margin-bottom: 20px;
        padding-left:40px ;
    }
</style>